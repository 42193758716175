const disallowedWords = [
    "antigua",
    "nazaré",
    "a happy death",
    "aarle-rixtel",
    "aberdeen",
    "abundance",
    "acheropita",
    "adam’s deliverance",
    "afflicted",
    "africa",
    "agonizing",
    "aix-la-chapelle",
    "akita",
    "alba royale",
    "alexandria",
    "aljmaš",
    "all fair and immaculate",
    "all hearts",
    "all saints",
    "almundena",
    "alotting",
    "altagracia",
    "altagracia",
    "altotting",
    "ambarawa",
    "amiens",
    "andacollo",
    "andoralla",
    "angels",
    "angels",
    "anguish",
    "annai arokiammarie",
    "apareceda",
    "apostles",
    "apparitions",
    "arabida",
    "arantzazu",
    "aranzazu",
    "ardenburg",
    "ardents",
    "ardilliers",
    "argenteuil",
    "armed forces",
    "arras",
    "assiut",
    "assumption",
    "atocha",
    "atonement",
    "auriesville",
    "bandora",
    "banneux",
    "barbana",
    "barking",
    "bath",
    "bavaria",
    "beaumount",
    "beauraing",
    "beauty",
    "bechouat",
    "begona",
    "bekaa",
    "belle fontaine",
    "benoite-vaux",
    "bessiere",
    "betania",
    "betharam",
    "bethlehem",
    "bitterness",
    "blessed heart",
    "bliss",
    "bolougne",
    "bolsward",
    "bonaria",
    "bonaria",
    "bonport",
    "boulogne",
    "bourbourg",
    "bourguillen",
    "bows",
    "bradley",
    "bradstaw",
    "broadstone",
    "bruges",
    "buch",
    "buglose",
    "calais",
    "calevourt",
    "calvary",
    "cambray",
    "cambron",
    "campitelli",
    "campocavallo",
    "cana",
    "canterbury",
    "caparoce",
    "caravaggio",
    "carmelites",
    "carquere",
    "castelbruedo",
    "cavadunga",
    "caysasay",
    "ceylon",
    "charity",
    "charkhapane",
    "chartres",
    "chatham",
    "chatillion",
    "chatillon sur seine",
    "chesapeake",
    "chievres",
    "china",
    "chiquinquirá",
    "citeaux",
    "clairvaux",
    "clarenburg",
    "class struggle",
    "clemency",
    "clermont",
    "clery",
    "clos-evrard",
    "co-redemptrix",
    "cobre",
    "coimbra",
    "comfort",
    "comfort to migrants",
    "compassion",
    "conception",
    "confessors",
    "confidence",
    "conquest",
    "consolation",
    "consolation of widows",
    "constantinople",
    "contemplation",
    "conversion" ,
    "copacabana",
    "coromoto",
    "coronation",
    "courtesy",
    "coutances",
    "covadonga",
    "covadonga",
    "craganor",
    "crazan",
    "czenstochawa",
    "czestochowa",
    "d'afrique",
    "d'iron",
    "dadizelle",
    "damascus",
    "damietta",
    "de gray",
    "de la brenche",
    "de la treille",
    "del parto",
    "deliverance",
    "dell'arco" ,
    "della croce",
    "deposition",
    "destroyer of heresy",
    "didinia",
    "dijon",
    "divine grace",
    "divine providence",
    "divine sheperd",
    "dolours",
    "donglu",
    "dordrecht",
    "dull students",
    "edessa",
    "egmanton",
    "egypt",
    "einsiedeln",
    "einsiedeln",
    "eiteren",
    "el cisne",
    "emminont",
    "ephesus",
    "ermesinde",
    "esquernes",
    "etchmiadzin",
    "eternal help",
    "eternal light",
    "eucharist",
    "exile",
    "faith",
    "fatima",
    "fertile rocks",
    "filermo",
    "filippsdorf",
    "fire",
    "flines",
    "flowering thorn",
    "foi",
    "fourviere",
    "foy",
    "france",
    "frieswijk",
    "galloro",
    "gamgok-myeon",
    "garabandal",
    "gate of heaven",
    "genauano",
    "genazzano",
    "genesta",
    "gifts",
    "glastonbury",
    "golden fountain",
    "good counsel",
    "good counsel",
    "good deliverance",
    "good haven",
    "good health",
    "good help",
    "good hope",
    "good luck",
    "good news",
    "good remedies",
    "good studies",
    "good success",
    "good tidings",
    "grace",
    "grace",
    "granganor",
    "great power",
    "grief",
    "guadalupe",
    "guam",
    "guápulo",
    "guard",
    "guidance",
    "haarlem",
    "hal",
    "happy assembly",
    "happy meetings" ,
    "hardenberg",
    "harihar",
    "haut",
    "healing",
    "health of the sick",
    "heaven",
    "heaven",
    "heiloo",
    "helbron",
    "help",
    "highest grace",
    "hildesheim",
    "hofstrada maria",
    "hope of triana",
    "hopeless cases",
    "immaculate conception",
    "intercession",
    "ipswich",
    "ipswich",
    "ireland",
    "isle of thanet",
    "jarinus",
    "jasna gura",
    "joy and sorrow",
    "juquila",
    "justinienne",
    "kaghtsrahayatse",
    "kanikkai madha",
    "kazan",
    "kent",
    "kevelaer",
    "kevelaer",
    "khuntragadare",
    "kibeho rwanda",
    "kieff",
    "kiev",
    "klaten",
    "kloštar",
    "knock",
    "korattymuthy",
    "kremlin",
    "kuehn",
    "kursk root",
    "la borradora",
    "la carolle",
    "la chapelle",
    "la conquistadira",
    "la guarde",
    "la kuen",
    "la macarena",
    "la naval",
    "la naval de manila",
    "la rochette",
    "la salette",
    "la vang",
    "lac bouchet",
    "lanka",
    "laon",
    "lapa",
    "las laja",
    "last agony",
    "laus",
    "lavang",
    "le puy",
    "lebanon",
    "leeuwarden",
    "leżajsk",
    "lichen",
    "liesse",
    "liesse",
    "life",
    "light",
    "ligny",
    "lincoln",
    "locarno or del sasso",
    "loretto",
    "los reyes",
    "lourdes",
    "louvain",
    "lujan",
    "luján",
    "łukawiec",
    "luxemburg",
    "luzendorf",
    "lynos",
    "madhu",
    "madonna",
    "madu",
    "mae de deus",
    "malibu",
    "manaoag",
    "mantara",
    "mantua",
    "mariamabad",
    "mariazel",
    "mariazell",
    "marienthal",
    "marienthal",
    "martyrs",
    "martyrs",
    "maryknoll",
    "matara",
    "matara",
    "matarieh",
    "maulicha dongar",
    "medjugorje",
    "meenangadi",
    "melchthal",
    "meliapore",
    "mellieħa",
    "merces",
    "mercy",
    "meritxell",
    "milan",
    "miracles",
    "mirror of justice",
    "miziara",
    "molanus",
    "molene",
    "molve",
    "mondevi",
    "montaigue",
    "montallegro",
    "montalto",
    "monte berico",
    "montemaria",
    "montevergine",
    "montserrat",
    "moreneta",
    "mot mauli",
    "mother inviolate",
    "mount carmel",
    "mount olive",
    "moustier",
    "moyen point",
    "muswell",
    "myan",
    "nanteuil",
    "naples",
    "narni",
    "naval",
    "navigators",
    "nazareth",
    "new eve",
    "new orleans",
    "new york",
    "ngome",
    "nicaragua",
    "notre-dame",
    "nourieh",
    "nyina wa jambo",
    "ocotlan",
    "october",
    "oegnies",
    "of ferguson and all those killed by gun violence",
    "of the cross",
    "of the troubled",
    "olovo",
    "oropa",
    "orphans",
    "ostrabrama",
    "oud-zevenaar",
    "oviedo",
    "pain",
    "paloma",
    "paradise",
    "parekhos",
    "paris",
    "parkway",
    "partzrahayatse",
    "patience",
    "patriarchs",
    "peace",
    "peace",
    "pellevoisin",
    "penha de frança",
    "penha de france",
    "perig",
    "perpetual help",
    "petchenza",
    "phillipines",
    "picciano",
    "pietraquaria",
    "piety",
    "pignerol",
    "pillar",
    "pity",
    "pivasiunai",
    "plymbridge",
    "pocszajow",
    "pomata",
    "pome",
    "pompeii",
    "pontmain",
    "pontoise",
    "poondi matha",
    "poovan banana's",
    "port louis",
    "pottery",
    "póvoa de varzim",
    "power",
    "prairie",
    "premontre",
    "prompt succor",
    "prompt succor against conflagrations",
    "prompt succor against contagious diseases and epidemics",
    "prompt succor against inundations",
    "prompt succor against lightning and tempest",
    "prompt succor against the enemies of our country",
    "prompt succor against the evil spirit",
    "prompt succor against the revolt of self-will",
    "prompt succor at the hour of death",
    "prompt succor for enlightening infidels",
    "prompt succor in every accident",
    "prompt succor in time of war",
    "prosperity",
    "providence",
    "pucha",
    "puducherry",
    "puig",
    "puy",
    "puy-en-velay",
    "quinche",
    "quintisola",
    "quito",
    "ransom",
    "ransom",
    "ratisbon",
    "reading on the thames",
    "ready help",
    "refuge of sinners",
    "refugees",
    "regina",
    "regla",
    "remedies",
    "remete",
    "rennes",
    "reparatrix",
    "rheims",
    "rocamadour",
    "rocío",
    "rocks",
    "rose even blooming",
    "rossano",
    "rouen",
    "sacred heart",
    "safety",
    "saibinn mai",
    "saideneida",
    "saidnaya",
    "saint acheul",
    "saint fort chartres",
    "saint john",
    "saint mary",
    "salvation",
    "sameiro",
    "san agostino",
    "san juan de los lagos",
    "sanctuary of the holy spirit",
    "santa maria",
    "sasopoli",
    "saussaie",
    "savigny",
    "scapular",
    "scherpenheuvel",
    "schiedam",
    "schier",
    "schilberg",
    "scutari",
    "seat of wisdom",
    "second eve",
    "seez",
    "sendangsono",
    "seven sorrows",
    "seven stars",
    "sevile",
    "sheshan",
    "siauliai",
    "sichem",
    "silence",
    "siluva",
    "šiluva",
    "sinj",
    "sinj",
    "sion",
    "sirahayatse",
    "smelcem",
    "socavon",
    "soissons",
    "soledad",
    "solitude",
    "sorrowful mothers",
    "sorrows",
    "sospoli",
    "south park",
    "speech",
    "spire",
    "spiritual vessel",
    "spouse of the holy spirit",
    "spring",
    "suburb of querétaro",
    "succor",
    "suffering",
    "suffrage",
    "sweet smelling balm",
    "tà pao",
    "ta' pinu",
    "tabernacle of god",
    "tabernacle of the word",
    "tables",
    "tal-ħerba",
    "talan",
    "talpa",
    "targa",
    "tears",
    "telgate",
    "tensta",
    "terouenne",
    "the abandoned sinners",
    "the advocate of eve",
    "the afflicted",
    "the americas",
    "the angelic" ,
    "the annunciation",
    "the anxious heart",
    "the apocalypse",
    "the apostles",
    "the aqueduct of grace",
    "the ardents",
    "the artichokes",
    "the battles",
    "the beech tree",
    "the bells",
    "the blessed among women",
    "the blessed mother",
    "the bush",
    "the candles",
    "the cape",
    "the cause of our joy",
    "the cause of our salvation",
    "the cenacle",
    "the church" ,
    "the compassionate heart",
    "the cord",
    "the coronation",
    "the crops",
    "the crucified",
    "the don",
    "the doves",
    "the dreche",
    "the empress",
    "the eraser",
    "the eucharist",
    "the faithful",
    "the ferns",
    "the fields",
    "the flies",
    "the flowers",
    "the forests",
    "the forsaken",
    "the fortress",
    "the foundatin, constantinople",
    "the founders",
    "the fountain",
    "the free",
    "the fruit of benediction",
    "the gate of dawn",
    "the glory of jerusalem",
    "the golden heart",
    "the grotto lamego",
    "the hebrew race",
    "the hermits",
    "the hill",
    "the holy cave",
    "the holy chapel",
    "the holy cross",
    "the holy ghost",
    "the holy spirit",
    "the house of gold",
    "the illustrious exile in egypt's land",
    "the immaculate heart",
    "the incarnation" ,
    "the infant savior",
    "the jesuit college",
    "the joy of israel",
    "the just",
    "the lily",
    "the lord’s home",
    "the lord’s palace",
    "the lord’s robe",
    "the lord’s tabernacle",
    "the madonna",
    "the merciful",
    "the milk",
    "the mines",
    "the miraculous medal",
    "the miserable",
    "the missions",
    "the morning star",
    "the most amiable"  ,
    "the most chaste"  ,
    "the most holy rosary",
    "the most holy trinity",
    "the most hopeless cases",
    "the most powerful",
    "the most prudent",
    "the most pure" ,
    "the most renowned",
    "the most venerable",
    "the most wretched",
    "the mother of christ",
    "the mount",
    "the mountains",
    "the mystical body",
    "the mystical rose",
    "the nativity",
    "the new eve",
    "the oak",
    "the oleaster tree",
    "the one chosen before the ages",
    "the palm",
    "the park",
    "the passion",
    "the patroness of vocations",
    "the peak",
    "the pillar",
    "the place",
    "the pond",
    "the poor",
    "the poor",
    "the portico",
    "the portuguese",
    "the precious blood",
    "the presentation",
    "the presentation of el quinche",
    "the purification",
    "the rock",
    "the rosary",
    "the rose",
    "the rotunda",
    "the rule",
    "the sacred heart",
    "the scapular",
    "the second eve",
    "the silver foot",
    "the slain",
    "the sleeve",
    "the snows",
    "the son",
    "the spire",
    "the star",
    "the star of the sea",
    "the storm",
    "the sun",
    "the swoon",
    "the taper",
    "the thirty three",
    "the thorn",
    "the thrice admirable",
    "the tower",
    "the tower of ivory",
    "the treasure house of god"
]
