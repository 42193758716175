window.addEventListener("DOMContentLoaded", () => {
    start();
}, false);

document.addEventListener("contextmenu", (e) => {
   e.preventDefault();
}, false);

function start() {
    const main = document.querySelector("main");
    if(main.id == "pwd") {
	// to disable pwd, comment out the startpwd line and uncomment the window.location line
	//window.location = "/start/";
	startPwd();
    } else {
	// to disable pwd, comment out the startMain line and uncomment the startItAlready line
	//startItAlready();
	startMain();
    }
}


function startPwd() {
    let oc = document.querySelector("#oc");
    let closeBtn = document.querySelector("#close-btn");

    deleteCookie();

    window.setTimeout(() => {
	let img = document.querySelector('img');
	let map = document.querySelector('map');
	let areas = map.querySelectorAll('area');
	let ratio = img.offsetWidth / map.dataset.width;

	for(let area of areas) {
	    let newCoords = [];
	    for(let coord of area.dataset.coords.split(",")) {
		newCoords.push(Math.round(coord * ratio))
	    }
	    area.coords = newCoords.join(",");
	}
    }, 100);
    document.querySelector("#letmein").addEventListener("click", (e) => {
	e.preventDefault();

	let pwd = prompt('Please enter your password');
	if(pwd) { 
	    validate(pwd, (v) => {
		if(v) {
		    document.cookie = `password=${pwd}; SameSite=strict`;
		    window.location = '/start/';
		} else {
		    alert('The password you entered is incorrect.');
		}
	    });
	}
    }, false);
    document.querySelector("#video").addEventListener("click", (e) => {
	e.preventDefault();

	const cVideo = document.createElement('video');
	const cVideoSrc = document.createElement('source');
	cVideoSrc.setAttribute("src", "/dist/video/circuit.mp4");
	cVideoSrc.setAttribute("type", "video/mp4");
	cVideoSrc.setAttribute("plays-inline", "plays-inline");
	cVideo.append(cVideoSrc);
	oc.append(cVideo);
	overlord.classList.add("possessed");
	cVideo.play();

	// position the close btn
	let imgPos = cVideo.getBoundingClientRect();
	closeBtn.style.left = imgPos.right - 18 - 10 + "px";

	cVideo.addEventListener("ended", (e) => {
	    closeVideo();
	}, false);

	function closeVideo() {
	    oc.innerHTML = "";
	    overlord.classList.remove("possessed");
	    oc.style.position = "";
	}
	// close content overlay and remove content
	closeBtn.addEventListener("click", closeVideo, false);
	overlord.addEventListener("click", (e) => {
	    if(e.target != e.currentTarget) {
		if(e.target == oc) {
		    closeVideo();
		}
		return;
	    }
	    closeVideo();
	}, false);
	document.addEventListener("keyup", (e) => {
	    if(e.key === "Escape") {
		closeVideo();
	    }
	}, false);
    }, false);
}


function startMain() {
    authenticate((a) => {
	if(a) {
	    startItAlready();
	} else {
	    window.location = "/";
	}
    });
}


function startItAlready() {
    const tiles = document.querySelectorAll(".tile");
    const reveal = document.querySelector("#reveal");
    const overlord = document.querySelector("#overlord");
    const oc = document.querySelector("#oc");
    const closeBtn = document.querySelector("#close-btn");
    const faces = document.querySelector("#faces");
    const circuitBoard = document.querySelector("[data-id=circuit-board-for-video]");
    const theme = document.querySelector("#theme");
    const mainEl = document.querySelector('main');
    const searchStart = document.querySelector("[data-id=search-start]");
    const searchPage = document.querySelector("#generate");
    const candle = document.querySelector('[data-offering=candle]');
    const mapAreas = document.querySelectorAll('map area');

    // play theme
    let startAutoPlay = theme.play();
    if(startAutoPlay !== undefined) {
	startAutoPlay.then(() => {
	}).catch(error => {
	    if(error.name === "NotAllowedError") {
		let errImg = document.createElement('img');
		errImg.style.width = "70vh";
		errImg.setAttribute("src", "/dist/images/autoplay-dialog-box.jpg");
		oc.append(errImg);
		overlord.classList.add("possessed");
		let imgRight = errImg.getClientRects()[0].right;
		closeBtn.style.left = imgRight - 18 - 10 + "px";
	    }
	});
    }

    // set timer to activate faces video after a specified time
    const facesTimeOut = 15000;
    window.setTimeout(() => {
	faces.classList.add("playing");
	faces.play();
    }, facesTimeOut);

    // populate disallowed box
    const disallowedBox = document.querySelector("#gen-page-2-disallowed");
    disallowedWords.forEach((d) => {
	let li = document.createElement("li");
	li.innerHTML = d;
	disallowedBox.append(li);
    });

    // close content overlay and remove content
    closeBtn.addEventListener("click", exorcise, false);
    overlord.addEventListener("click", (e) => {
	if(e.target != e.currentTarget) {
	    if(e.target == oc) {
		exorcise();
	    }
	    return;
	}
	exorcise();
    }, false);
    document.addEventListener("keyup", (e) => {
	if(e.key === "Escape") {
	    exorcise();
	}
    }, false);

    function exorcise() {
	oc.innerHTML = "";
	overlord.classList.remove("possessed");
	// remove faces video from top if required
	faces.style.zIndex = "";
	// offerings tiles should NOT be on top
	let offeringTiles = document.querySelectorAll('.offerings');
	offeringTiles.forEach((o) => {
	    o.style.zIndex = "";
	});
	oc.style.position = "";
	theme.volume = 1;
    }

    // play facces video when area is clicked
    faces.addEventListener("click", (e) => {
	faces.classList.add("playing");
	faces.play();
    }, false);

    // play circuitboard video when circuit board is clicked
    circuitBoard.addEventListener("click", (e) => {
	theme.volume = 0;
	const cVideo = document.createElement('video');
	const cVideoSrc = document.createElement('source');
	cVideoSrc.setAttribute("src", "/dist/video/circuit.mp4");
	cVideoSrc.setAttribute("type", "video/mp4");
	cVideoSrc.setAttribute("plays-inline", "plays-inline");
	cVideo.append(cVideoSrc);
	oc.append(cVideo);
	overlord.classList.add("possessed");
	cVideo.play();

	// position the close btn
	let imgPos = cVideo.getBoundingClientRect();
	closeBtn.style.left = imgPos.right - 18 - 10 + "px";

	cVideo.addEventListener("ended", (e) => {
	    exorcise();
	}, false);
    }, false);

    // reveal all tiles on candle click
    candle.addEventListener("click", (e) => {
	let candleImg = candle.querySelector("img");

	let opacity = candleImg.classList.contains("on") ? 0 : 1;
	let tls = document.querySelectorAll('.tile:not([data-type=hovers]):not([data-type=mm]):not([data-type=offerings]) img');
	tls.forEach((t) => {
	    t.style.opacity = opacity;
	});

	candleImg.classList.toggle("on");
    }, false);


    // unhide elements
    function unhide(els) {
	els.forEach((i) => {
	    i.style.display = "block";
	});
    }

    // hide elements
    function hide(els) {
	els.forEach((i) => {
	    i.style.display = "none";
	});
    }

    // show search page 1 on search
    searchStart.addEventListener("click", (e) => {
	searchPage.style.display = "flex";
	let genLink = document.querySelector("#gen-page-1-generate");
	let page1 = document.querySelectorAll(".gen-page-1");
	let page2 = document.querySelectorAll(".gen-page-2");
	let page3 = document.querySelectorAll(".gen-page-3");
	let frm = searchPage.querySelector('form');
	let searchQ = document.querySelector("#gen-page-2-q");
	let errs = document.querySelectorAll(".gen-page-2-err");
	let errIndex = 0;

	// form submission
	frm.addEventListener("submit", (e) => {
	    e.preventDefault();
	    let formData = new FormData(frm);
	    fetch("/", {
		method: 'POST',
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: new URLSearchParams(formData).toString()
	    }).then(() => {
		exitSearch();
	    }).catch((error) => {
		console.log(error);
	    });
	}, false);

	// page 2
	genLink.addEventListener("click", (e) => {
	    e.preventDefault();


	    // show page 2, hide page 1, bring up and position faces
	    hide(page1);
	    unhide(page2);
	    faces.classList.add("sp2");


	    // handle search submission
	    function handleSearch() {
		const keyWord = searchQ.value.toLowerCase();

		// keyword is in list
		if(disallowedWords.includes(keyWord)) {
		    let dialog1 = document.querySelector("#gen-page-2-dialog-1");
		    dialog1.style.display = "none";

		    // hide current error
		    let lastErrBox = document.querySelector('.gen-page-2-err.current');
		    if(lastErrBox) {
			lastErrBox.classList.remove("current");
		    }

		    // show error
		    let errBox = document.querySelector('#gen-page-2-err-' + errIndex);
		    errBox.classList.add("current");
		    errIndex = errIndex == 2 ? 0 : errIndex + 1;

		// keyword not in list: show page 3
		} else if(keyWord != "") {
		    disallowedBox.classList.remove("isopen");
		    faces.classList.remove("sp2");
		    faces.classList.add("sp3");
		    hide(page2);
		    unhide(page3);

		    let namePlate = searchPage.querySelector("#gen-page-3-name span");
		    namePlate.innerText = searchQ.value;
		}
	    }

	    // show disallowed word list on click or down arrow
	    searchQ.focus();
	    searchQ.addEventListener("click", (e) => {
		disallowedBox.classList.toggle("isopen");
	    }, false);

	    searchQ.addEventListener("keyup", (e) => {
		if(e.key === "Enter") {
		    e.preventDefault();
		    return false;
		}

		if(e.key === "ArrowDown") {
		    disallowedBox.classList.add("isopen");
		} else if(e.key === "ArrowUp") {
		    disallowedBox.classList.remove("isopen");
		} else if(e.key === "Enter") {
		    e.preventDefault();
		}
	    }, false);

	    searchQ.addEventListener("keydown", (e) => {
		if(e.key === "Enter") {
		    e.preventDefault();
		    handleSearch();
		}
	    });

	    searchQ.addEventListener("change", (e) => {
		handleSearch();
	    }, false);

	}, false);

	function exitSearch() {
	    searchPage.style.display = "";

	    faces.classList.remove("sp2");
	    faces.classList.remove("sp3");

	    let formEls = searchPage.querySelectorAll('input[type=text], [type=email], textarea');
	    formEls.forEach((fel) => {
		fel.value = "";
	    });
	    
	    hide(page2);
	    hide(page3);
	    unhide(page1);

	    // hide current error
	    let lastErrBox = document.querySelector('.gen-page-2-err.current');
	    if(lastErrBox) {
		lastErrBox.classList.remove("current");
	    }
	}

	searchPage.addEventListener("click", (e) => {
	    if(e.target != e.currentTarget) {
		return;
	    }
	    exitSearch();
	}, false);

	document.addEventListener("keyup", (e) => {
	    if(e.key === "Escape") {
		exitSearch();
	    }
	}, false);
	
	// close btn
	const closeSearch = searchPage.querySelector('.close-btn');
	closeSearch.addEventListener("click", (e) => {
	    exitSearch();
	}, false);

	// position the close btn
	let imgPos = frm.getBoundingClientRect();
	closeSearch.style.left = imgPos.right - 18 - 36 + "px";


    }, false);

    function resizeImageMap(mapName, img) {
	let map = document.querySelector('[name=' + mapName + ']');
	let areas = map.querySelectorAll('area');
	let ratio = img.offsetWidth / map.dataset.width;

	for(let area of areas) {
	    let newCoords = [];
	    for(let coord of area.dataset.coords.split(",")) {
		newCoords.push(Math.round(coord * ratio))
	    }
	    area.coords = newCoords.join(",");
	}
    }

    function getTarget(link) {
	let target = null;
	target = document.querySelector('[data-content=' + link + ']');

	if(!target) {
	    //target = document.querySelector('[data-offering=' + link + ']');
	    target = null; // disabled offering check, jump to img embed

	    if(!target) {
		let img = "/dist/images/content/" + link + ".jpg";
		if(fileExists(img)) {
		    target = document.createElement('img');
		    target.src = img;
		}
	    }
	}
	return target;
    }

    // handle image map clicks
    mapAreas.forEach((a) => {
	a.addEventListener("click", (e) => {
	    if(a.dataset.linkType == "internal") {
		e.preventDefault();
		let link = a.getAttribute("href");
		let target = getTarget(link);
		if(target) {
		    if(target.tagName == "IMG") {
			faces.style.zIndex = "";
			oc.innerHTML = "";
			oc.append(target);
		    } else {
			exorcise();
			target.click();
		    }
		}
	    }
	}, false);
    });

    tiles.forEach((tile) => {
	// hide all tile images unless it is mm or offering
	if(tile.dataset.type != "mm" && tile.dataset.type != "offerings") {
	    let img = tile.querySelector('img');
	    img.style.opacity = 0;
	}

	// get data
	let audioEl = tile.dataset.audio ? document.querySelector('#' + tile.dataset.audio) : false;
	let wrd = tile.dataset.word ? tile.dataset.word : false;
	let cnt = tile.dataset.content ? tile.dataset.content : false;
	let hoverTarget = false;
	let hoverImg = false;

	tile.addEventListener("click", (e) => {
	    // reveal/paint tile images on click
	    tile.querySelector("img").style.opacity = 1;

	    // for offerings, content is in the 'offering' data attr
	    if(tile.dataset.type == "offerings") {
		cnt = tile.dataset.offering;
		hoverTarget = document.querySelector('[data-source=' + tile.dataset.offering + '_hover]');
		if(hoverTarget) {
		    hoverImg = hoverTarget.querySelector('img');
		} else {
		    cnt = false;
		}
	    }

	    // if content is present, create image node and insert it
	    if(cnt) {
		// paint all tiles that link to the same content
		let tileGroup = document.querySelectorAll('[data-content=' + cnt + '] > img')
		tileGroup.forEach((t) => {
		    t.style.opacity = 1;
		});

		// bring faces video on top for hair content
		if(tile.dataset.type == "hair") {
		    faces.style.zIndex = "1001";
		}

		let cntImgUri = "/dist/images/content/" + cnt + ".jpg";
		let cntImg = document.createElement("img");
		cntImg.setAttribute("src", cntImgUri);

		// change positioning for offerings posts to stand above pedestal
		if(tile.dataset.type == "offerings") {
		    // get position of pedestal and calculate grid cell size for top offset
		    let pedestalRect = document.querySelector('[data-offering=pedestal]').getBoundingClientRect();
		    let gridRect = document.querySelector('main').getBoundingClientRect();
		    let gridCellSize = document.querySelector('main').offsetHeight/30;

		    // requires absolute positioning
		    oc.style.position = "relative";
		    cntImg.style.position = "absolute";
		    cntImg.style.top = gridCellSize + "px";
		    // height should also include the third sized moon tiles just above the pedestal
		    cntImg.style.height = pedestalRect.top - gridCellSize + (gridCellSize/3) + "px";
		    cntImg.style.width = "auto";

		    // offerings tiles should be on top
		    let offeringTiles = document.querySelectorAll('.offerings');
		    offeringTiles.forEach((o) => {
			o.style.zIndex = "1002";
		    });
		}
		
		// append image
		oc.append(cntImg);
		overlord.classList.add("possessed");


		window.setTimeout(() => {
		    // assign map if it exists
		    if(tile.dataset.map) {
			cntImg.setAttribute("usemap", "#" + tile.dataset.map);
			resizeImageMap(tile.dataset.map, cntImg);
		    }

		    // position the close btn after a small timeout to ensure img is positioned
		    let imgPos = cntImg.getClientRects()[0];
		    closeBtn.style.left = imgPos.right - 18 - 10 + "px";
		}, 500);

	    }
	}, false);

	tile.addEventListener("mouseover", () => {
	    // play audio if it exists
	    if(audioEl) {
		audioEl.play();
	    }
	    // reveal word if it exists
	    if(wrd) {
		reveal.innerText = wrd;
		reveal.style.opacity = 1;
	    }
	    // show hover image if offering
	    if(tile.dataset.type === "offerings") {
		//let hoverTarget = document.querySelector('[data-source=' + tile.dataset.offering + '_hover]');
		//if(hoverTarget) {
		    //let hoverImg = hoverTarget.querySelector('img');
		    //hoverImg.style.opacity = 1;
		//}
	    }
	}, false);

	tile.addEventListener("mouseout", () => {
	    // pause audio if it exists
	    if(audioEl) {
		audioEl.pause();
		audioEl.currentTime = 0;
	    }
	    // hide word if it exists
	    if(wrd) {
		reveal.innerHTML = "";
		reveal.style.opacity = 0;
	    }
	    // hide hover image if offering
	    if(tile.dataset.type === "offerings") {
		//let hoverTarget = document.querySelector('[data-source=' + tile.dataset.offering + '_hover]');
		//if(hoverTarget) {
		    //let hoverImg = hoverTarget.querySelector('img');
		    //hoverImg.style.opacity = 0;
		//}
	    }
	}, false);

    });
}


function fileExists(fileURI) {
    let xhr = new XMLHttpRequest();
    xhr.open('HEAD', fileURI);
    xhr.send();
    return xhr.status != 404;
}

function validate(pwd, cb) {
    let xhr = new XMLHttpRequest();
    let url = `/auth/${pwd}/`;
    xhr.open('HEAD', url);
    xhr.send();

    xhr.onload = function() {
	cb(xhr.status === 200);
    };
}

function authenticate(cb) {
    let pwd = getCookie('password');
    if(!pwd) {
	cb(false);
    }

    validate(pwd, (v) => {
	if(v) {
	    console.log('authenticated');
	    cb(true);
	} else {
	    cb(false);
	}
    });
}

function getCookie(cookieName) {
    var name = cookieName + "=";
    var allCookieArray = document.cookie.split(';');
    for(var i=0; i<allCookieArray.length; i++) {
	var temp = allCookieArray[i].trim();
	if (temp.indexOf(name)==0) {
	    return temp.substring(name.length,temp.length);
	}
    }
    return "";
}

function deleteCookie() {
    document.cookie = "password= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict";
}
